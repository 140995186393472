import React from "react";
import Styled from "@emotion/styled";
import {
  TColorVar,
  TFontSize,
  TFontWeight,
} from "shared/models/types/styles-var.types";

export interface ITitleThickThin {
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  titleClass?: string;
  thinClass?: string;
  thickTitle: string;
  thinTitle: string;
  align?: "center" | "left" | "right";
  color?: TColorVar;
  fw?: TFontWeight;
  fs?: TFontSize;
  mb?: string; // value on px
}

const TitleThickThin: React.FC<ITitleThickThin> = (props) => {
  const {
    component = "h2",
    align = "center",
    fw = 600,
    color = "--color-blue-main",
    fs = "--fs-sub-title-large",
    mb = "60px",
    ...rest
  } = props;
  const Title = Styled[component]`
    margin: auto;
    font-family: var(--font-family);
    font-size: var(${fs});
    font-weight: ${fw};
    line-height: 21px;
    letter-spacing: 0px;
    color: var(${color});
    text-align: ${align};
    margin-bottom:${mb} !important;
    & span{
      font-weight: 400;
      font-family: var(--font-family);
    }
  `;

  return (
    <Title className={props.titleClass}>
      {props.thickTitle}{" "}
      <span className={props.thickTitle}>{props.thinTitle}</span>
    </Title>
  );
};

export default TitleThickThin;
