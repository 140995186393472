import styled from "@emotion/styled";
import AdvanceFilterDialog from "shared/components/advance-filter-dialog";
import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useWidth } from "custom-hooks/use-width.hook";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { CPagesPath } from "shared/models/const/pages-path";
import { useIntlPropertyTypes } from "shared/models/const/property-types";
import { getRealestateDetails } from "api/realestate.request";
import { ModalMessage } from "shared/components/message-modal";

import RadioGroupStatus from "./RadioGroupStatus";
import AutoCompleteField, {
  AutoCompleteField as DefaultAutoComplete,
} from "../auto-complete-field";
import { FormConfig, makeFilterObject } from "./util/form.util";
import useCities from "custom-hooks/use-cities.hook";
import sortCities from "@helpers/cities.helper";
import _ from "lodash";

function BuySellFilter(props: any) {
  const t = useTranslations("buy-sell-filter");
  const translationNotFound = useTranslations("buy-sell-filter.notFound");
  const translation = useTranslations("realestateList.typeFilters");
  const width = useWidth();
  const isMobile = ["xs", "sm"].includes(width);
  const ref = useRef<FormikProps<any>>(null);
  const refButton = useRef<any>();
  const router = useRouter();
  const [search, onSearch] = useState("");
  const [open, setOpen] = useState<boolean>(false);

  const propertyTypes = useIntlPropertyTypes();
  const isCollection = router.asPath.includes(CPagesPath.es.collection);

  const propertyDetailRedirect = isCollection
    ? CPagesPath.es.collection_property_detail
    : CPagesPath.es.property_detail;

  const collectionProperties = [
    translation("house"),
    translation("apartment"),
    translation("tourist"),
    translation("penthouse"),
    translation("project"),
  ];

  const collectionTypes = propertyTypes.filter((e) =>
    collectionProperties.includes(e.name)
  );

  const types = props.collection ? collectionTypes : propertyTypes;

  const handleSearch = React.useCallback((e: any) => {
    const values = e.target.value;
    onSearch(values);
  }, []);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const citiesList = useCities(
    {
      q: search,
    },
    500
  );

  const getRealestateRequest = useMutation(
    ["detail-realstate"],
    (realestateId: number) => {
      return getRealestateDetails(realestateId);
    }
  );

  const submitValues = _.debounce((value: any) => {
    const realestateId = parseInt(search);

    if (Boolean(realestateId)) {
      getRealestateRequest
        .mutateAsync(realestateId)
        .then((r) => {
          router.push({
            pathname: propertyDetailRedirect(r.data),
            query: { ...router.query, ...props.params },
          });
        })
        .catch(() => handleOpenModal());
    } else {
      const route =
        props.redirect ??
        (router.pathname.length > 1 ? router.pathname : "") +
          CPagesPath.es.properties;

      const params = makeFilterObject({ ...value, ...props.params });
      router.push({
        pathname: route,
        query: params,
      });
    }
  }, 500);

  const handleLocationChange = (e: any, newValue: any) => {
    // if enter was pressed
    if (e.keyCode === 13 && Boolean(parseInt(search))) {
      submitValues({});
    } else if (ref.current) {
      if (newValue.length == 2) {
        ref.current.setFieldValue("locations", [newValue[1]]);
        return;
      }
      ref.current.setFieldValue("locations", newValue);
    }
  };

  const isLoading = () => {
    if (Boolean(parseInt(search))) {
      return false;
    }

    return citiesList.isLoading;
  };

  const handleModal = () => {
    refButton.current.click();
  };

  const Cities_Options = citiesList.data ? sortCities(citiesList.data) : [];

  const Cities_noOptionsText = !(search?.length > 2)
    ? t("searchHelp")
    : t("searchHelpSD");
  const initialValues = props.values ? props.values : FormConfig.initialValues;
  const handleSubmit = props.onSubmit ? props.onSubmit : submitValues;
  const Cities_getOptionLabel = (option: any) => option?.description;
  const Cities_renderOptions = (props: any, option: any) => (
    <ItemElementLabel {...props} {...option}></ItemElementLabel>
  );

  const FormikConfig: FormikConfig<any> = {
    innerRef: ref,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  };

  return (
    <FilterContent className={props.className}>
      <Formik {...FormikConfig}>
        {(formikProp) => (
          <CoverSearch id="cover_search">
            {!isMobile && (
              <RadioGroupStatus name="businessTypes"></RadioGroupStatus>
            )}
            <CoverSearchBody className="content-body">
              <Grid container>
                <Grid
                  item
                  xs={4}
                  className="typeProperty-autocomplete-container"
                >
                  <Label>{t("youLookingFor")}</Label>
                  <CoverAutoComplete
                    name="typeProperty"
                    limitTags={1}
                    multiple={true}
                    className="typeProperty-autocomplete"
                    placeholder={t("typePlaceholder")}
                    getOptionLabel={(option: any) => option?.description}
                    options={types.map((x) => ({
                      ...x,
                      description: x.name,
                      id: x.id,
                    }))}
                  ></CoverAutoComplete>
                </Grid>

                <div>
                  <DividerSearch></DividerSearch>
                </div>

                <Grid
                  item
                  xs
                  onClick={() => {
                    if (isMobile) handleModal();
                  }}
                >
                  <Label>{t("where")}</Label>
                  <LocationAutoComplete
                    name="locations"
                    limitTags={1}
                    multiple={true}
                    INPUT_DELAY={600}
                    loading={isLoading()}
                    options={Cities_Options}
                    onInputChange={handleSearch}
                    className="city-autocomplete"
                    onChange={handleLocationChange}
                    renderOption={Cities_renderOptions}
                    value={formikProp.values.locations}
                    noOptionsText={Cities_noOptionsText}
                    freeSolo={Boolean(parseInt(search))}
                    placeholder={t("locationPlaceholder")}
                    getOptionLabel={Cities_getOptionLabel}
                  />
                </Grid>
              </Grid>

              <ButtonSubmit type="submit">
                <img
                  src="/icons/loupe_remax_rd.svg"
                  alt="Loupe icon"
                  width="35px"
                  height="35px"
                />
              </ButtonSubmit>
            </CoverSearchBody>

            <AdvanceFilterDialog
              handleReset={formikProp.handleReset}
              handleSubmit={formikProp.handleSubmit}
              refButton={refButton}
            >
              <ButtonFilter type="button">
                <img
                  src="/icons/filter_remax_rd.svg"
                  alt="Filter icon"
                  width="11px"
                  height="11px"
                />{" "}
                {t("AdvanceFilters")}
                {props.filtersCount > 0 && <Badge>{props.filtersCount}</Badge>}
              </ButtonFilter>
            </AdvanceFilterDialog>
          </CoverSearch>
        )}
      </Formik>
      <ModalMessage
        title={translationNotFound("title")}
        body={translationNotFound("body")}
        variant="error"
        open={open}
        handleClose={handleCloseModal}
      />
    </FilterContent>
  );
}

export default BuySellFilter;

const ItemElementLabel = (props: any) => {
  const { citie, description, isSector, ...rest } = props;
  const Wrap = description?.length > 8 ? "wrap" : "";

  return (
    <Item {...rest}>
      <span className={`noWrap isCity`}>{description}</span>
    </Item>
  );
};

const Label = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  color: var(--color-blue-main);
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  &.MuiAutocomplete-option.Mui-focused {
    background-color: transparent;
    &:hover span {
      color: white;
    }
  }
  .noWrap {
    white-space: nowrap;
  }
  & span {
    color: var(--color-blue-main);
    font-weight: bold;
    margin-right: 5px;
  }
  & .isCity,
  .isSector {
    color: var(--color-blue-main);
  }

  & .description {
    color: #989694;
    line-height: 20px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: start;
    white-space: nowrap;
    text-align: left;
    font-size: 13px;
    & .isCity {
      width: 100%;
    }
    & .description {
      text-align: left;
      font-weight: 400;
      width: 100%;
    }
  }
`;

const ButtonSubmit = styled.button`
  background-color: #1f3564;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 15px;
  position: absolute;
  right: -10px;
  align-self: center;
  cursor: pointer;
  & img {
    vertical-align: middle;
  }
  @media screen and (max-width: 767px) {
    & img {
      width: 20px;
    }
  }
  @media screen and (max-width: 450px) {
    right: -10px;
  }
`;

const DividerSearch = styled(Grid)`
  border-left: 1px solid var(--color-blue-main);
  height: 49px;
  width: 1px;
  margin: auto 10px;
  margin-top: 5px;
  @media screen and (max-width: 740px) {
    display: none;
  }
`;

const ButtonFilter = styled.button`
  position: absolute;
  top: 100%;
  right: 80px;
  background-color: #e92027;
  color: #fff;
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 0 0 10px 10px;
  font-size: 0.7rem;
  cursor: pointer;
  font-weight: 700;
  & img {
    vertical-align: middle;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const CoverSearchBody = styled.div`
  background-color: #fff;
  position: relative;
  z-index: 10;
  padding: 8px 38px 10px 33px;
  border-radius: 50px;
  display: flex;
  height: 75px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 15px 30px;
    height: auto;
  }
  @media screen and (max-width: 450px) {
    border-radius: 10px;
    padding: 15px 15px;
  }
`;

const CoverSearch = styled(Form)`
  position: relative;
  width: 840px;
  @media screen and (max-width: 991px) {
    width: 700px;
  }
  @media screen and (max-width: 891px) {
    width: 600px;
  }
  @media screen and (max-width: 740px) {
    width: 570px;
  }
  @media screen and (max-width: 732px) {
    width: 520px;
  }
  @media screen and (max-width: 630px) {
    // width: 550px;
    width: 440px;
  }
  @media screen and (max-width: 450px) {
    width: 340px;
  }
`;

const CoverAutoComplete = styled(AutoCompleteField)`
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  & .MuiPaper-root.MuiAutocomplete-noOptions {
    text-transform: initial !important;
  }
  & .MuiInputBase-fullWidth.MuiInputBase-root {
    flex-wrap: nowrap;
    &.Mui-focused {
      position: absolute;
      flex-wrap: wrap;
    }
  }
  & .MuiInputBase-root {
    margin-top: -5px;
    padding: 0px !important;
    margin-top: -3px;
    background-color: white;
    & * {
      border: none !important;
    }
  }
  & .MuiAutocomplete-tag {
    max-width: 110px;
    background-color: var(--color-blue-main);
    color: white;
    font-weight: 500;
  }

  & .MuiChip-deleteIcon {
    color: white !important;
  }
  & span.MuiAutocomplete-tagSizeMedium {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding:3px;
  }
  & .Mui-focused .MuiAutocomplete-tag {
    max-width: calc(100% - 6px);
  }

  & .city-autocomplete {
    width: 100%;
  }
`;

const LocationAutoComplete = styled(DefaultAutoComplete)`
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  & .MuiPaper-root.MuiAutocomplete-noOptions {
    text-transform: initial !important;
  }
  & .MuiInputBase-fullWidth.MuiInputBase-root {
    flex-wrap: nowrap;
    &.Mui-focused {
      position: absolute;
      flex-wrap: wrap;
    }
  }
  & .MuiInputBase-root {
    margin-top: -5px;
    padding: 0px !important;
    margin-top: -3px;
    background-color: white;
    & * {
      border: none !important;
    }
  }
  & .MuiAutocomplete-tag {
    // max-width: 110px;
    background-color: var(--color-blue-main);
    color: white;
    font-weight: 500;
  }

  & .MuiChip-deleteIcon {
    color: white !important;
  }
  & span.MuiAutocomplete-tagSizeMedium {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding:3px;
  }
  & .Mui-focused .MuiAutocomplete-tag {
    max-width: calc(100% - 6px);
  }

  & .city-autocomplete {
    width: 100%;
  }
`;

const FilterContent = styled.div`
  margin: auto;
  @media screen and (max-width: 991px) {
  }
  @media screen and (max-width: 777px) {
  }
  @media screen and (max-width: 740px) {
    & .typeProperty-autocomplete-container {
      display: none;
    }
  }
`;

const Badge = styled.div`
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: var(--white);
  background-color: var(--color-blue-main);
  font-size: ${12 / 16}rem;
  font-weight: 600;
  top: 18px;
  right: 0;
`;
