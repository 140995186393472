import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React from "react";
import { useTranslations } from "use-intl";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

function CustomLabel(props: any) {
  const { active, label, onChange = () => {} } = props;
  return (
    <Wrapper>
      <StyledCheckbox checked={active} onClick={onChange} />
      <Label>{label}</Label>
    </Wrapper>
  );
}

const RadioGroupStatus = (props: any) => {
  const t = useTranslations("buy-sell-filter");
  const [field, meta, helpers] = useField(props as any);

  const onChange = () => {
    const value = field.value == "sale" ? "lease" : "sale";

    helpers.setValue(value);
  };

  return (
    <FormControl css={Styles.root}>
      <RadioGroup row={true} {...field}>
        <FormControlLabel
          className={field.value == "sale" ? "active" : ""}
          value="sale"
          control={<Radio />}
          label={
            <CustomLabel
              label={t("shop")}
              active={field.value == "sale"}
              onChange={onChange}
            />
          }
        />
        <FormControlLabel
          value="lease"
          className={field.value == "lease" ? "active" : ""}
          control={<Radio />}
          label={
            <CustomLabel
              label={t("sell")}
              active={field.value == "lease"}
              onChange={onChange}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupStatus;

const Styles = {
  root: css({
    "& .MuiFormGroup-root": {
      display: "flex",
      flexWrap: "nowrap",
      position: "relative",
      left: 40,
      bottom: -3,
      "@media screen and (max-width: 991px)": {
        left: 20,
      },
      "@media screen and (max-width: 767px)": {
        left: 20,
      },
    },
    "& .MuiFormControlLabel-root": {
      margin: "0px",
      backgroundColor: "var(--color-secondary)",
      color: "#fff",
      padding: "8px 20px",
      border: "none",
      outline: "none",
      borderRadius: "10px 10px 0px 0px",

      "@media screen and (max-width: 991px)": {
        borderRadius: "10px 10px 10px 10px",
      },
      "@media screen and (max-width: 767px)": {
        borderRadius: "10px 10px 10px 10px",
      },

      "&.active": {
        backgroundColor: "var(--color-blue-main)",
        transform: "scale(1.1)",
        transition: "0.2s",
        fontSize: "12px",
      },
      "& .MuiRadio-root": {
        display: "none",
        textAlign: "center",
      },
      "& .MuiFormControlLabel-label": {
        cursor: "pointer",
        fontWeight: 500,
        fontFamily: "var(--font-family)",
        width: "100%",
        textAlign: "center",
        fontSize: "12px",
      },
    },
  }),
};

const Label = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-family: var(--font-family);
  width: 100%;
  text-align: center;
  font-size: 12px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin-right: 5px;

  & .MuiSvgIcon-root {
    width: 15px;
    height: 15px;
    color: var(--white);
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
